.hero-bg {
  background: var(
    --n,
    linear-gradient(
      241deg,
      rgba(47, 218, 255, 0.2) 15.85%,
      rgba(0, 0, 0, 0) 164.18%
    ),
    linear-gradient(200deg, #0069ff 20.84%, #0f4c82 93.53%)
  );
}
.app-bg {
  background: var(
    --n,
    linear-gradient(
      241deg,
      rgba(47, 218, 255, 0.2) 15.85%,
      rgba(0, 0, 0, 0) 164.18%
    ),
    linear-gradient(270deg, #0069ff 20.84%, #0f4c82 93.53%)
  );
}
.overflow-ellipsis {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Glow button */
.btn-101,
.btn-101 *,
.btn-101 :after,
.btn-101 :before,
.btn-101:after,
.btn-101:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-101 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  /* font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; */
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  /* margin: 0; */
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
}
.btn-101:disabled {
  cursor: default;
}
.btn-101:-moz-focusring {
  outline: auto;
}
.btn-101 svg {
  vertical-align: middle;
}
.btn-101 [hidden] {
  display: none;
}
.btn-101 {
  --thickness: 0.3rem;
  --roundness: 1rem;
  --color: #eff6ff;
  --opacity: 0.6;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  background: none;
  background: hsla(0, 0%, 100%, 0.2);
  border: none;
  border-radius: var(--roundness);
  color: var(--color);
  cursor: pointer;
  display: block;
  /* font-family: Poppins, "sans-serif"; */
  font-size: 1rem;
  font-weight: 500;
  padding: 0.8rem 3rem;
  position: relative;
}
.btn-101:hover {
  background: hsla(0, 0%, 100%, 0.3);
  filter: brightness(1.2);
}
.btn-101:active {
  --opacity: 0;
  background: hsla(0, 0%, 100%, 0.1);
}
.btn-101 svg {
  border-radius: var(--roundness);
  display: block;
  filter: url(#glow);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.btn-101 rect {
  fill: none;
  stroke: var(--color);
  stroke-width: var(--thickness);
  rx: var(--roundness);
  stroke-linejoin: round;
  stroke-dasharray: 185%;
  stroke-dashoffset: 80;
  -webkit-animation: snake 3s linear infinite;
  animation: snake 3s linear infinite;
  /* -webkit-animation-play-state: paused;
  animation-play-state: paused; */
  height: 100%;
  /* opacity: 0; */
  transition: opacity 0.2s;
  width: 100%;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  opacity: var(--opacity);
}
.btn-101:hover rect {
  -webkit-animation-play-state: running;
  animation-play-state: running;
  opacity: var(--opacity);
}
@-webkit-keyframes snake {
  to {
    stroke-dashoffset: 370%;
  }
}
@keyframes snake {
  to {
    stroke-dashoffset: 370%;
  }
}

.menu-label {
  position: relative;
}

.menu-label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffffff; /* Adjust as needed */
  bottom: 0;
  left: 0;
  transition: transform 0.3s ease-out;
  transform: scaleX(0); /* Initially hidden */
  transform-origin: left;
}

.menu-label:hover::before {
  transform: scaleX(1); /* Slide in from left to right on hover */
}

.news-card:hover .image-wrapper img {
  transform: scale(1.1); /* Adjust the scaling factor as per your preference */
  transition: transform 0.3s ease; /* Smooth transition effect */
}

#six {
  background-color: rgb(239 251 255);
  position: relative;
  clip-path: polygon(
    0% 0%,
    0% 100%,
    0% calc(100% - 5vw),
    10% calc(100% - 2vw),
    20% calc(100% - 4vw),
    30% calc(100% - 1vw),
    40% calc(100% - 7vw),
    50% calc(100% - 5vw),
    60% calc(100% - 2vw),
    70% calc(100% - 6vw),
    80% calc(100% - 4vw),
    90% calc(100% - 6vw),
    100% calc(100% - 5vw),
    100% 0%
  );
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    0% calc(100% - 5vw),
    10% calc(100% - 2vw),
    20% calc(100% - 4vw),
    30% calc(100% - 1vw),
    40% calc(100% - 7vw),
    50% calc(100% - 5vw),
    60% calc(100% - 2vw),
    70% calc(100% - 6vw),
    80% calc(100% - 4vw),
    90% calc(100% - 6vw),
    100% calc(100% - 5vw),
    100% 0%
  );
  margin-bottom: 50px;
  &::after {
    background-color: rgba(1, 66, 126, 0.4);
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(
      0% 0%,
      0% 100%,
      0% calc(100% - 2vw),
      8% calc(100% - 6vw),
      28% calc(100% - 4vw),
      38% calc(100% - 3vw),
      48% calc(100% - 7vw),
      58% calc(100% - 4vw),
      68% calc(100% - 5vw),
      78% calc(100% - 6vw),
      88% calc(100% - 4vw),
      98% calc(100% - 2vw),
      100% calc(100% - 4vw),
      100% 0%
    );
    -webkit-clip-path: polygon(
      0% 0%,
      0% 100%,
      0% calc(100% - 2vw),
      8% calc(100% - 6vw),
      28% calc(100% - 4vw),
      38% calc(100% - 3vw),
      48% calc(100% - 7vw),
      58% calc(100% - 4vw),
      68% calc(100% - 5vw),
      78% calc(100% - 6vw),
      88% calc(100% - 4vw),
      98% calc(100% - 2vw),
      100% calc(100% - 4vw),
      100% 0%
    );
    bottom: calc(-100% + 12vw);
    transform: scale(-1, -1);
  }
  &::before {
    background-color: rgba(1, 66, 126, 0.2);
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(
      0% 0%,
      0% 100%,
      0% calc(100% - 2vw),
      13% calc(100% - 6vw),
      23% calc(100% - 4vw),
      33% calc(100% - 3vw),
      43% calc(100% - 7vw),
      53% calc(100% - 4vw),
      63% calc(100% - 5vw),
      73% calc(100% - 6vw),
      83% calc(100% - 4vw),
      93% calc(100% - 2vw),
      100% calc(100% - 4vw),
      100% 0%
    );
    -webkit-clip-path: polygon(
      0% 0%,
      0% 100%,
      0% calc(100% - 2vw),
      13% calc(100% - 6vw),
      23% calc(100% - 4vw),
      33% calc(100% - 3vw),
      43% calc(100% - 7vw),
      53% calc(100% - 4vw),
      63% calc(100% - 5vw),
      73% calc(100% - 6vw),
      83% calc(100% - 4vw),
      93% calc(100% - 2vw),
      100% calc(100% - 4vw),
      100% 0%
    );
    bottom: calc(-100% + 12vw);
    transform: scale(1, -1);
  }
}

/* ----- Feature card ----- */
.feature-style {
  h3 {
    color: #04aadd;
  }
}
.gradient-bg {
  background: linear-gradient(
      180deg,
      rgba(1, 66, 126, 0.03),
      rgba(1, 66, 126, 0)
    ),
    #f0faff; /* Lighter background color */
}
