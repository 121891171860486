@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: auto;
  position: relative;
  z-index: 1;
  margin: 0;
  /* overflow-y: scroll; */
  /* overscroll-behavior-y: contain; Prevent rubber-banding effect on some browsers */

  font-family: "Kanit", sans-serif; /* font-family: "Inter", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  /* position: absolute; */
  width: 6px;
  z-index: 2;
  background: transparent;
  display: none;
}
:hover::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(150, 150, 150, 0.5);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(150, 150, 150, 0.7);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.long-card {
  width: 100%;
  min-height: 100px;
  border: 0.1px solid #343a40;
  text-align: left;
  color: #afafaf;
  display: flex;
  margin-bottom: 10px;
}
.long-card:hover {
  text-decoration: none;
  color: #afafaf;
}
.long-card:hover .title {
  color: #aaccff;
  text-decoration: underline;
}
.long-card .title {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  transition: color 0.2s;
}
.long-card-body {
  padding: 40px 30px;
}
.remarks {
  font-size: 0.9rem;
}
